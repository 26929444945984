"use client"

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  type ReactNode,
} from "react"

import { useParams, usePathname, useRouter } from "next/navigation"
import { useSession } from "next-auth/react"
import { toast } from "sonner"

import {
  getPlaygroundAgentsAPI,
  getPlaygroundEndpointsAPI,
  getSessionAPI,
  deletePlaygroundSessionAPI,
} from "@/api/playground"
import { constructEndpointUrl } from "@/components/common/Code/utils"
import { type DefaultPageParams } from "@/types/globals"
import { getWorkspaceUrl } from "@/utils/workspace"

import {
  type Agent,
  type HistoryEntry,
  type PlaygroundChatMessage,
  type PlaygroundContextType,
} from "./types"
import updateURLParams from "./updateURLParams"

const PlaygroundContext = createContext<PlaygroundContextType | undefined>(
  undefined,
)

export const PlaygroundContextProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const params = useParams<DefaultPageParams>()
  const pathname = usePathname()
  const router = useRouter()
  const session = useSession()

  const [agents, setAgents] = useState<Agent[]>([])
  const [endpoints, setEndpoints] = useState<string[]>([])
  const [error, setError] = useState<string | null>(null)
  const [messages, setMessages] = useState<PlaygroundChatMessage[]>([])
  const [hasFetched, setHasFetched] = useState(false)
  const [selectedEndpoint, setSelectedEndpoint] = useState<string | null>(null)
  const [selectedAgent, setSelectedAgent] = useState<string | null>(null)
  const [sessionId, setSessionId] = useState<string | null>(null)
  const [userName, setUserName] = useState<string | null>()
  const [historyData, setHistoryData] = useState<HistoryEntry[]>([])
  const [monitor, setMonitor] = useState<boolean>(true)
  const chatInputRef = useRef<HTMLInputElement>(null)
  const renameInputRef = useRef<HTMLInputElement>(null)
  const [endpointLoading, setEndpointLoading] = useState<boolean>(true)
  const [currentEndpointStatus, setCurrentEndpointStatus] = useState<
    string | null
  >(null)
  const [loadEndpoint, setLoadEndpoint] = useState<boolean>(false)

  // Set Username
  useEffect(() => {
    if (!userName) {
      setUserName(session.data?.user?.username)
    }
  }, [userName, session.data?.user?.username])

  const checkEndpoint = useCallback(async () => {
    if (hasFetched) return

    try {
      const workspaceUrl = getWorkspaceUrl(params.workspace)
      const teamURL =
        params.account !== session.data?.user?.username
          ? params.account
          : undefined

      const response = await getPlaygroundEndpointsAPI({
        workspace_url: workspaceUrl,
        team_url: teamURL,
      })

      const newEndpoints = response.body.map((endpoint) => endpoint.endpoint)
      setEndpoints(newEndpoints)

      setHasFetched(true)
    } catch (err) {
      setEndpoints([])
      setError("Failed to connect to the endpoint")
      setHasFetched(true)
    } finally {
      setEndpointLoading(false)
    }
  }, [
    hasFetched,
    params.workspace,
    params.account,
    session.data?.user?.username,
  ])
  const fetchAgents = useCallback(
    async (endpoint: string) => {
      try {
        const response = await getPlaygroundAgentsAPI(endpoint)
        setAgents(response.body)
        const searchParams = new URLSearchParams(window.location.search)
        searchParams.set("agent", response.body[0].agent_id)
      } catch (err) {
        toast.error(err instanceof Error ? err.message : "An error occurred")
      }
    },
    [setAgents],
  )
  const refresh = useCallback(async () => {
    setEndpointLoading(true)
    setHasFetched(false)
    await checkEndpoint()
  }, [checkEndpoint, setEndpointLoading, setHasFetched])

  useEffect(() => {
    if (pathname.includes("/playground") && !hasFetched) {
      checkEndpoint()
    }
  }, [pathname, hasFetched, checkEndpoint])

  useEffect(() => {
    if (!pathname.includes("/playground")) return

    const searchParams = new URLSearchParams(window.location.search)
    if (currentEndpointStatus === "inactive") return
    const agentParam = searchParams.get("agent")
    const sessionParam = searchParams.get("session")

    if (agentParam && !hasFetched) {
      setSelectedAgent(agentParam)
    } else if (agents.length > 0) {
      setSelectedAgent(agents[0].agent_id)
    }

    if (sessionParam) {
      setSessionId(sessionParam)
    }
  }, [
    pathname,
    agents,
    endpoints,
    selectedEndpoint,
    currentEndpointStatus,
    hasFetched,
  ])

  useEffect(() => {
    updateURLParams(pathname, selectedAgent, selectedEndpoint, sessionId)
  }, [pathname, selectedAgent, selectedEndpoint, sessionId])

  const clearMessages = useCallback(() => {
    setMessages([])
  }, [])

  const addMessage = useCallback((message: PlaygroundChatMessage) => {
    setMessages((prevMessages) => [...prevMessages, message])
  }, [])

  const updateSessionId = useCallback(
    (newSessionId: string | null) => {
      setSessionId(newSessionId)
      const currentParams = new URLSearchParams(window.location.search)
      if (newSessionId) {
        currentParams.set("session", newSessionId)
      } else {
        currentParams.delete("session")
      }
      router.replace(`${pathname}?${currentParams.toString()}`)
    },
    [pathname, router],
  )

  const loadSession = useCallback(
    async (selectedSessionId: string, selectedAgentId: string | null) => {
      try {
        const searchParams = new URLSearchParams(window.location.search)

        const agentId = selectedAgentId ?? searchParams.get("agent")
        const endpoint = constructEndpointUrl(selectedEndpoint!)

        if (!agentId || !endpoint) {
          throw new Error("Agent ID or Endpoint is missing")
        }

        const response = await getSessionAPI(
          selectedSessionId,
          agentId,
          endpoint,
        )

        if (typeof response.body === "object" && response.body !== null) {
          const agentSession = response.body
          if (
            agentSession.memory?.chats &&
            Array.isArray(agentSession.memory.chats)
          ) {
            const messagesForPlayground: PlaygroundChatMessage[] =
              agentSession.memory.chats.flatMap((chat) => {
                const filteredMessages: PlaygroundChatMessage[] = []

                if (chat.message) {
                  filteredMessages.push({
                    role: "user",
                    content: chat.message.content ?? "",
                  })
                }

                if (chat.response) {
                  filteredMessages.push({
                    role: "agent",
                    content: (chat.response.content as string) ?? "",
                    tool_calls: chat.response.tools,
                  })
                }
                return filteredMessages
              })
            setMessages(messagesForPlayground)
            updateSessionId(selectedSessionId)
          } else {
            throw new Error("Invalid session data structure")
          }
        } else {
          throw new Error("Invalid response format")
        }
      } catch (err) {
        setError(
          err instanceof Error
            ? err.message
            : "An error occurred while loading the session",
        )
      }
    },
    [selectedEndpoint, updateSessionId],
  )

  const clearChat = useCallback(() => {
    setMessages([])
    updateSessionId(null)
  }, [updateSessionId])

  const deleteSession = useCallback(
    async (sessionIdToDelete: string) => {
      if (!selectedAgent || !selectedEndpoint) {
        throw new Error("Selected agent or endpoint is missing")
      }

      try {
        await deletePlaygroundSessionAPI(
          sessionIdToDelete,
          selectedAgent,
          constructEndpointUrl(selectedEndpoint),
        )
        setHistoryData((prevData) =>
          prevData.filter((entry) => entry.session_id !== sessionIdToDelete),
        )
        if (sessionIdToDelete === sessionId) {
          clearChat()
        }
      } catch (deleteError) {
        toast.error("Failed to delete session")
      }
    },
    [selectedAgent, selectedEndpoint, clearChat, sessionId],
  )

  const value = useMemo(
    () => ({
      userName,
      agents,
      setAgents,
      addMessage,
      clearMessages,
      messages,
      error,
      setMessages,
      endpoints,
      setEndpoints,
      monitor,
      setMonitor,
      chatInputRef,
      selectedAgent,
      selectedEndpoint,
      setSelectedAgent,
      setSelectedEndpoint,
      sessionId,
      updateSessionId,
      clearChat,
      historyData,
      setHistoryData,
      loadSession,
      renameInputRef,
      endpointLoading,
      currentEndpointStatus,
      setCurrentEndpointStatus,
      checkEndpoint,
      refresh,
      setLoadEndpoint,
      loadEndpoint,
      fetchAgents,
      deleteSession,
    }),
    [
      userName,
      agents,
      addMessage,
      clearMessages,
      messages,
      error,
      endpoints,
      monitor,
      setMonitor,
      chatInputRef,
      selectedAgent,
      selectedEndpoint,
      sessionId,
      updateSessionId,
      clearChat,
      historyData,
      setHistoryData,
      loadSession,
      renameInputRef,
      endpointLoading,
      currentEndpointStatus,
      setCurrentEndpointStatus,
      checkEndpoint,
      refresh,
      setLoadEndpoint,
      loadEndpoint,
      fetchAgents,
      deleteSession,
    ],
  )

  return (
    <PlaygroundContext.Provider value={value}>
      {children}
    </PlaygroundContext.Provider>
  )
}

export function usePlayground() {
  const context = useContext(PlaygroundContext)
  if (context === undefined) {
    throw new Error(
      "usePlayground must be used within a PlaygroundContextProvider",
    )
  }
  return context
}
