import { type APIPlanID } from "./User"

export enum TeamPermission {
  read = "read",
  review = "review",
  edit = "edit",
  admin = "admin",
  owner = "owner",
}

export interface ITeam {
  id_team: string
  name?: string
  url?: string
  domain?: string
  is_active?: boolean
  created_by?: string
  permission: "read" | "review" | "edit" | "admin" | "owner"
}

export interface ITeamUser {
  id_team: string
  id_user: string
  is_active: boolean
  email: string
  permission: TeamPermission
}

export interface ITeamPlan {
  id_team: string
  plan: APIPlanID
  plan_data: object | null
  is_active: boolean | null
}

export interface ITeamMember {
  id_team: string
  member_email: string
  permission: TeamPermission
  is_invited: boolean
  is_joined: boolean
  is_removed: boolean
}
