import { APIRoutes } from "@/api/routes"
import { CACHE_KEYS } from "@/constants"
import {
  type Agent,
  type AgentSession,
} from "@/contexts/PlaygroundContext/types"
import { request } from "@/utils/request"

export const getPlaygroundAgentsAPI = async (endpoint: string) =>
  request<Agent[]>(APIRoutes.ReadPlaygroundAgents(endpoint), "GET", {
    includeUserParams: true,
    tags: [CACHE_KEYS.PLAYGROUND_AGENT({ endpoint })],
  })

export const getPlaygroundStatusAPI = (base?: string) =>
  request<{ status: number }>(`${base}/v1/playground/status`, "GET", {
    includeUserParams: true,
  }).then((response) => response.status)

export const renamePlaygroundSessionAPI = async (
  sessionId: string,
  name: string,
  agentId: string,
  PlaygroundApiUrl: string,
) =>
  request<{ message: string }>(
    APIRoutes.RenameSession(PlaygroundApiUrl),
    "POST",
    {
      includeUserParams: true,
      body: { session_id: sessionId, name, agent_id: agentId },
    },
  )

export const deletePlaygroundSessionAPI = async (
  sessionId: string,
  agentId: string,
  PlaygroundApiUrl: string,
) =>
  request<{ message: string }>(
    APIRoutes.DeleteSession(PlaygroundApiUrl),
    "POST",
    {
      includeUserParams: true,
      body: {
        session_id: sessionId,
        agent_id: agentId,
      },
    },
  )

interface Workspace {
  workspace_url: string
  team_url: string | undefined
}
interface Endpoint {
  id_workspace: string
  id_playground_endpoint: string
  endpoint: string
  playground_data: object
}
export const getPlaygroundEndpointsAPI = async (workspace: Workspace) =>
  request<Endpoint[]>(APIRoutes.GetPlaygroundEnpoints, "POST", {
    includeUserParams: true,
    body: {
      workspace,
    },
    tags: [CACHE_KEYS.PLAYGROUND_ENDPOINT(workspace)],
  })

export const getSessionAPI = async (
  sessionId: string,
  agentId: string,
  PlaygroundApiUrl: string,
) => {
  const url = APIRoutes.GetSession(PlaygroundApiUrl).replace(
    "{session_id}",
    sessionId,
  )

  return request<AgentSession>(url, "POST", {
    includeUserParams: true,
    body: {
      agent_id: agentId,
    },
  })
}
