"use client"

import {
  createContext,
  type FC,
  useState,
  useContext,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react"

import { useParams } from "next/navigation"

import type {
  WorkspaceContextProviderProps,
  WorkspaceContextValue,
} from "./types"
import type { DefaultPageParams } from "@/types/globals"
import type { IWorkspace } from "@/types/Workspace"

const WorkspaceContext = createContext<WorkspaceContextValue | undefined>(
  undefined,
)

export const WorkspaceContextProvider: FC<WorkspaceContextProviderProps> = ({
  children,
}) => {
  const params = useParams<DefaultPageParams>()
  const [allWorkspaces, setAllWorkspaces] = useState<IWorkspace[]>([])
  const deletedWorkspaceIds = useRef<Set<string>>(new Set())
  const isInitialized = useRef(false)

  const currentWorkspace = useMemo(
    () =>
      allWorkspaces.find((workspace) => workspace.url === params.workspace) ??
      null,
    [allWorkspaces, params],
  )

  const updateWorkspace = useCallback((updatedWorkspace: IWorkspace) => {
    setAllWorkspaces((previousWorkspaces) => {
      const index = previousWorkspaces.findIndex(
        (workspace) => workspace.id_workspace === updatedWorkspace.id_workspace,
      )
      if (index !== -1) {
        const newWorkspaces = [...previousWorkspaces]
        newWorkspaces[index] = updatedWorkspace
        return newWorkspaces
      }
      return [...previousWorkspaces, updatedWorkspace]
    })
  }, [])

  const removeWorkspace = useCallback((workspaceId: string) => {
    setAllWorkspaces((previousWorkspaces) =>
      previousWorkspaces.filter(
        (workspace) => workspace.id_workspace !== workspaceId,
      ),
    )
    deletedWorkspaceIds.current.add(workspaceId)
  }, [])

  const createMultipleWorkspaces = useCallback((workspaces: IWorkspace[]) => {
    if (isInitialized.current) return // Prevent re-initialization

    setAllWorkspaces((previousWorkspaces) => {
      const newWorkspaces = workspaces.filter(
        (newWorkspace) =>
          !previousWorkspaces.some(
            (existingWorkspace) =>
              existingWorkspace.id_workspace === newWorkspace.id_workspace,
          ) && !deletedWorkspaceIds.current.has(newWorkspace.id_workspace),
      )
      isInitialized.current = true
      return [...previousWorkspaces, ...newWorkspaces]
    })
  }, [])

  const TransferWorkspace = useCallback(
    (workspaceId: string, newTeamId: string | null) => {
      setAllWorkspaces((previousWorkspaces) => {
        if (newTeamId) {
          // If transferring to a team, remove the workspace from the list
          return previousWorkspaces.filter(
            (workspace) => workspace.id_workspace !== workspaceId,
          )
        }
        // If transferring back to the user (newTeamId is null), update the team_id
        return previousWorkspaces.map((workspace) =>
          workspace.id_workspace === workspaceId
            ? { ...workspace, team_id: null }
            : workspace,
        )
      })

      // If transferring to a team, add to deletedWorkspaceIds
      if (newTeamId) {
        deletedWorkspaceIds.current.add(workspaceId)
      }
    },
    [],
  )
  const addWorkspace = useCallback((newWorkspace: IWorkspace) => {
    setAllWorkspaces((previousWorkspaces) => {
      // Check if the workspace already exists
      const exists = previousWorkspaces.some(
        (workspace) => workspace.id_workspace === newWorkspace.id_workspace,
      )
      if (exists) {
        // If it exists, update it
        return previousWorkspaces.map((workspace) =>
          workspace.id_workspace === newWorkspace.id_workspace
            ? newWorkspace
            : workspace,
        )
      }
      // If it doesn't exist, add it
      return [...previousWorkspaces, newWorkspace]
    })
  }, [])

  const resetWorkspaces = useCallback(() => {
    setAllWorkspaces([])
    deletedWorkspaceIds.current.clear()
    isInitialized.current = false
  }, [])

  useEffect(() => {
    resetWorkspaces()
  }, [params.account, resetWorkspaces])

  const value = useMemo(
    () => ({
      allWorkspaces,
      currentWorkspace,
      updateWorkspace,
      removeWorkspace,
      createMultipleWorkspaces,
      TransferWorkspace,
      addWorkspace,
      resetWorkspaces,
    }),
    [
      allWorkspaces,
      currentWorkspace,
      updateWorkspace,
      removeWorkspace,
      createMultipleWorkspaces,
      TransferWorkspace,
      addWorkspace,
      resetWorkspaces,
    ],
  )

  return (
    <WorkspaceContext.Provider value={value}>
      {children}
    </WorkspaceContext.Provider>
  )
}

export const useWorkspaceContext = () => {
  const context = useContext(WorkspaceContext)
  if (!context) {
    throw new Error(
      "useWorkspaceContext must be used within a WorkspaceContextProvider.",
    )
  }
  return context
}
