import { separateHostnameAndPort } from "@/components/common/Code/utils"

const updateURLParams = (
  pathname: string,
  selectedAgent: string | null,
  selectedEndpoint: string | null,
  sessionId: string | null,
) => {
  if (!pathname.includes("/playground")) return
  const currentParams = new URLSearchParams(window.location.search)

  if (selectedAgent) {
    currentParams.set("agent", selectedAgent)
  } else {
    currentParams.delete("agent")
  }

  if (selectedEndpoint) {
    const { hostname, port } = separateHostnameAndPort(selectedEndpoint)
    currentParams.set("endpoint", hostname)
    if (port) {
      currentParams.set("port", port)
    } else {
      currentParams.delete("port")
    }
  } else {
    currentParams.delete("endpoint")
    currentParams.delete("port")
  }

  if (sessionId) {
    currentParams.set("session", sessionId)
  } else {
    currentParams.delete("session")
  }

  const newURL = `${pathname}?${currentParams.toString()}`
  window.history.pushState({}, "", newURL)
}

export default updateURLParams
