"use client"

import {
  createContext,
  type FC,
  useState,
  useContext,
  useMemo,
  useCallback,
  useEffect,
} from "react"

import { useParams, usePathname } from "next/navigation"
import { toast } from "sonner"

import useOnce from "@/hooks/useOnce"
import { UNAUTHENTICATED_ROUTES } from "@/routes"
import { TeamPermission, type ITeam } from "@/types/Team"
import { logError } from "@/utils/error"

import type {
  TeamContextProviderProps,
  TeamContextValue,
  TeamPlanMap,
} from "./types"
import type { DefaultPageParams } from "@/types/globals"

import { getAllTeams } from "./serverActions"

const TeamContext = createContext<TeamContextValue | undefined>(undefined)

export const TeamContextProvider: FC<TeamContextProviderProps> = ({
  children,
}) => {
  const params = useParams<DefaultPageParams>()
  const pathname = usePathname()
  const [allTeams, setAllTeams] = useState<ITeam[]>([])
  const [teamPlans, setTeamPlans] = useState<TeamPlanMap>({})
  const [isLoading, setIsLoading] = useState(true)

  const mainAppRoutes = Object.values(UNAUTHENTICATED_ROUTES)
  const isViewingApp = mainAppRoutes.includes(pathname) === false

  const currentTeam = useMemo(
    () => allTeams.find((team) => team.url === params.account) ?? null,
    [allTeams, params],
  )

  const currentTeamPlan = useMemo(
    () => (currentTeam?.url ? teamPlans[currentTeam.url] : null),
    [currentTeam, teamPlans],
  )

  const isAdmin = useMemo(
    () => currentTeam?.permission === TeamPermission.admin,
    [currentTeam],
  )

  const isOwner = useMemo(
    () => currentTeam?.permission === TeamPermission.owner,
    [currentTeam],
  )

  const updateTeam = useCallback((updatedTeam: ITeam) => {
    setAllTeams((previousTeams) => [
      ...previousTeams.filter((team) => team.id_team !== updatedTeam.id_team),
      updatedTeam,
    ])
  }, [])

  const fetchTeams = useCallback(async () => {
    try {
      const teams = await getAllTeams()
      setAllTeams(teams)
    } catch (error) {
      toast.error(
        "Oops, something went wrong whilst fetching the teams you are in",
      )

      if (error) {
        logError(error as Error)
      }
    } finally {
      setIsLoading(false)
    }
  }, [setAllTeams])

  const value = useMemo(() => {
    const val: TeamContextValue = {
      isLoading,
      allTeams,
      currentTeam,
      currentTeamPlan,
      isAdmin,
      isOwner,
      updateTeam,
      updateTeamPlan: (teamURL, plan) => {
        setTeamPlans((previousPlans) => ({
          ...previousPlans,
          [teamURL]: plan,
        }))
      },
      createTeam: (team) => {
        // Assume admin permission for newly created teams
        const teamWithPermission = { ...team, permission: TeamPermission.owner }
        setAllTeams((previousTeams) => [...previousTeams, teamWithPermission])
      },
      deleteTeam: (team) => {
        setAllTeams((previousTeams) =>
          previousTeams.filter((t) => t.id_team !== team.id_team),
        )
      },
    }

    return val
  }, [
    isLoading,
    allTeams,
    currentTeam,
    currentTeamPlan,
    updateTeam,
    isAdmin,
    isOwner,
  ])

  useOnce(
    () => {
      fetchTeams()
    },
    undefined,
    isViewingApp,
  )

  // Effect to update team permissions after initial load
  useEffect(() => {
    if (!isLoading && allTeams.length > 0) {
      const teamsNeedingUpdate = allTeams.filter(
        (team) => team.permission === undefined,
      )
      if (teamsNeedingUpdate.length > 0) {
        fetchTeams()
      }
    }
  }, [isLoading, allTeams, fetchTeams])

  return <TeamContext.Provider value={value}>{children}</TeamContext.Provider>
}

export const useTeamContext = () => {
  const context = useContext(TeamContext)

  if (!context) {
    throw new Error(
      "`useTeamContext` must be used within a `TeamContextProvider`.",
    )
  }

  return context
}
